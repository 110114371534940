
import { productsMixin } from '~/mixins'

export default {
  name: 'TelemedicLanding',
  navigation: {
    disableTransparency: true,
    pageFiles: {},
  },
  mixins: [productsMixin],
  data() {
    return {
      officeValue: '',
      sliderOptions: {
        serviceSlider: {
          navButtons: false,
          slidesToShow: 1,
        },
      },
    }
  },
  computed: {
    productTag() {
      return 'telemedic-landing'
    },
    serviceSlider() {
      return [
        {
          title: this.$t('front.telemedic.landing.service1.title'),
          hash: '#corporatenews',
          image: this.pageFiles['front.telemedic.landing.service1.image'],
          link: this.$t('front.telemedic.landing.service1.cta_link'),
          description: this.$t('front.telemedic.landing.service1.description'),
        },
        {
          title: this.$t('front.telemedic.landing.service2.title'),
          hash: '#corporatenews',
          image: this.pageFiles['front.telemedic.landing.service2.image'],
          link: this.$t('front.telemedic.landing.service2.cta_link'),
          description: this.$t('front.telemedic.landing.service2.description'),
        },
        {
          title: this.$t('front.telemedic.landing.service3.title'),
          hash: '#events',
          image: this.pageFiles['front.telemedic.landing.service3.image'],
          link: this.$t('front.telemedic.landing.service3.cta_link'),
          description: this.$t('front.telemedic.landing.service3.description'),
        },
      ]
    },
    mainSlider() {
      return [
        {
          title: this.$t('front.telemedic.landing.main1.title'),
          subtitle: this.$t('front.telemedic.landing.main1.subtitle'),
          description: this.$t('front.telemedic.landing.main1.description'),
          image: this.pageFiles['front.telemedic.landing.main1.image'],
          icons: [
            this.pageFiles['front.telemedic.landing.main1.icon1'],
            this.pageFiles['front.telemedic.landing.main1.icon2'],
            this.pageFiles['front.telemedic.landing.main1.icon3'],
          ],
        },
        {
          title: this.$t('front.telemedic.landing.main2.title'),
          subtitle: this.$t('front.telemedic.landing.main2.subtitle'),
          description: this.$t('front.telemedic.landing.main2.description'),
          image: this.pageFiles['front.telemedic.landing.main2.image'],
          icons: [
            this.pageFiles['front.telemedic.landing.main2.icon1'],
            this.pageFiles['front.telemedic.landing.main2.icon2'],
            this.pageFiles['front.telemedic.landing.main2.icon3'],
          ],
        },
        {
          title: this.$t('front.telemedic.landing.main3.title'),
          subtitle: this.$t('front.telemedic.landing.main3.subtitle'),
          description: this.$t('front.telemedic.landing.main3.description'),
          image: this.pageFiles['front.telemedic.landing.main3.image'],
          icons: [
            this.pageFiles['front.telemedic.landing.main3.icon1'],
            this.pageFiles['front.telemedic.landing.main3.icon2'],
            this.pageFiles['front.telemedic.landing.main3.icon3'],
          ],
        },
      ]
    },
    fullBgImages() {
      return {
        desktop: this.pageFiles['front.telemedic.landing.fullBg.desktop'],
        mobile: this.pageFiles['front.telemedic.landing.fullBg.mobile'],
      }
    },
    twoColumnsImage() {
      return this.pageFiles['front.telemedic.landing.two_columns.image']
    },
  },
  created() {
    this.pageFiles = this.$store.state?.page?.content?.files || {}
  },
  methods: {
    setActiveToMain({ currentSlide }) {
      this.mainSliderActive = currentSlide
    },
  },
}
